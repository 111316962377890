import { Link } from '@remix-run/react'
import { css } from '#styled-system/css/css.js'

export const LogoLink = () => {
	return (
		<Link
			className={css({
				display: 'inline-flex',
				flexWrap: 'nowrap',
				alignItems: 'center',
				gap: '8px',
				borderRadius: '6px',
			})}
			to="/"
		>
			<svg
				className={css({
					height: '28px',
					width: 'auto',
				})}
				width="26"
				height="29"
				viewBox="0 0 26 29"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12.733 2.08691L23.647 8.22601V20.5042L12.733 26.6433L1.81909 20.5042V8.22601L12.733 2.08691Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.2816 1.37886C11.8037 0.522697 13.6622 0.522697 15.1842 1.37886L22.9172 5.72864C24.4916 6.61423 25.4659 8.28015 25.4659 10.0865V18.6439C25.4659 20.4503 24.4916 22.1162 22.9172 23.0018L15.1842 27.3516C13.6622 28.2077 11.8037 28.2077 10.2816 27.3516L2.54869 23.0018C0.974309 22.1162 0 20.4503 0 18.6439V10.0865C0 8.28015 0.97431 6.61423 2.54869 5.72864L10.2816 1.37886ZM6.18668 7.8563C4.61229 8.74189 3.63798 10.4078 3.63798 12.2142V16.5162C3.63798 18.3226 4.61229 19.9885 6.18667 20.8741L10.2816 23.1775C11.8037 24.0337 13.6622 24.0337 15.1842 23.1775L19.2792 20.8741C20.8536 19.9885 21.8279 18.3226 21.8279 16.5162V12.2142C21.8279 10.4078 20.8536 8.7419 19.2792 7.85631L15.1842 5.55289C13.6622 4.69673 11.8037 4.69673 10.2816 5.55289L6.18668 7.8563Z"
					fill="white"
				/>
				<path
					d="M12.733 2.08691L23.647 8.22601V20.5042L12.733 26.6433L1.81909 20.5042V8.22601L12.733 2.08691Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.2816 2.42232C11.8036 1.56615 13.6621 1.56616 15.1842 2.42232L22.0076 6.2605C23.582 7.1461 24.5563 8.81201 24.5563 10.6184V18.1119C24.5563 19.9183 23.582 21.5842 22.0076 22.4698L15.1842 26.308C13.6621 27.1642 11.8036 27.1642 10.2816 26.308L3.45812 22.4698C1.88373 21.5842 0.909424 19.9183 0.909424 18.1119V10.6184C0.909424 8.81202 1.88374 7.14609 3.45812 6.2605L10.2816 2.42232ZM5.27711 7.32434C3.70272 8.20993 2.72841 9.87585 2.72841 11.6822V17.0481C2.72841 18.8545 3.70273 20.5204 5.27711 21.406L10.2816 24.221C11.8036 25.0771 13.6621 25.0771 15.1842 24.221L20.1886 21.406C21.763 20.5204 22.7373 18.8545 22.7373 17.0481V11.6822C22.7373 9.87585 21.763 8.20993 20.1886 7.32434L15.1842 4.50933C13.6621 3.65317 11.8036 3.65317 10.2816 4.50933L5.27711 7.32434Z"
					fill="#111111"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19.0973 5.68939L9.13084 11.2955L12.7327 13.3216L23.4001 7.3212L24.2918 8.90659L13.6422 14.897V26.9923H11.8232V14.897L7.27571 12.339L7.25977 12.348L7.24501 12.3218L1.17358 8.90659L2.06536 7.3212L7.27571 10.252L18.2055 4.104L19.0973 5.68939Z"
					fill="#111111"
				/>
				<path
					d="M20.5823 12.9775L19.4163 13.6305V17.624L19.3676 17.6513L16.9762 14.997L15.9482 15.5727V21.9227L17.1196 21.2667V17.2701L17.1602 17.2473L19.5705 19.8941L20.5823 19.3275V12.9775Z"
					fill="#111111"
				/>
			</svg>

			<span
				className={css({
					fontSize: '20px',
					lineHeight: '28px',
					fontWeight: '900',
					color: 'gray12',
				})}
			>
				Notion Icons
			</span>
			<span
				className={css({
					borderRadius: '9999px',
					backgroundColor: 'primary5',
					paddingInline: '8px',
					paddingBlock: '4px',
					fontSize: '12px',
					lineHeight: '1',
					fontWeight: '500',
					textTransform: 'uppercase',
					letterSpacing: '0.025em',
					color: 'primary12',
				})}
			>
				beta
			</span>
		</Link>
	)
}
